import Container from "react-bootstrap/Container";
import {Button} from "react-bootstrap";
import React from "react";

const SignupCallout = () => {
    return (
        <section className="bg-light py-10 justify-content-center">
            <Container>
                <div className="row mt-5 mb-5 text-center">
                    <div className="col">
                        <h2>Collect 1,000,000 Events for Free</h2>
                        <p className="fs-lg text-muted mb-7 mb-md-9">Get started with Firstparty for free. No credit card required.</p>
                        <Button href="https://app.firstpartyhq.com/authentication/signup">Get Started</Button>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default SignupCallout